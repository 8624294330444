import './AboutUs.css';
import { createScroll } from './helpers/createScroll';
import { isChrome, isMobile } from '../../../shared/helpers.js';

import React, { Fragment } from 'react';
import styled from 'styled-components';

const isDesktop = window.innerWidth > 992

class Origins extends React.Component {
  componentDidMount() {
    if (isDesktop) {
      createScroll();
    }
  }

  get body() {
    return (
      <Fragment>
        <p>
          { isDesktop ? <Lorem>C</Lorem> : <span>&nbsp;&nbsp;&nbsp;&nbsp;C</span> }
          yrus Interpreting has been active in interpreting for over 20 years.
          DSHS State and American Translators Association certified.  Proudly
          served in many US and UK agencies such as Cyracom, Telelanguage, Pacific
          Interpreters, Language Services Associates, Network Omni, Cross Cultural
          Communications, Optima Interpreters, and UK Department of Home Office.
        </p>
        <FoundersList>
          &nbsp;&nbsp;&nbsp;&nbsp;Founder Raz Mostafavi, ATA Certified, <i>&nbsp;ATA Id: 248002</i>
          {!isMobile() && (
            <ScrollCrest
              className="lozad"
              data-src={isChrome ? require('./images/founder-razmostafavi.webp') : require('./images/founder-razmostafavi.png')}
              alt="Founder"
            />
          )}
        </FoundersList>
      </Fragment>
    )
  }

  render() {
    if (isDesktop) {
      return (
        <div id="container">
          <div id="content">
            <ScrollTitle>
              <h1>The Founding</h1>
              <h2>of</h2>
              <h1>Cyrus Interpreting</h1>
            </ScrollTitle>
            { this.body }
          </div>
        </div>
      );
    }
    return (
      <div>
        { this.body }
      </div>
    );
  }
}

export { Origins };

const ScrollTitle = styled.header`
  margin-top: 30px;
  text-align: center;

  > {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
  }

  & h1 {
    font-size: 45px;
  }

  & h2 {
    font-size: 25px;
  }
`;

const Lorem = styled.span`
  @media (min-width: 992px) {
    font-size: 7em;
    color: var(--primary-color);
    float: left;
    line-height: 1;
    margin-right: 20px;
  }
`;

const FoundersList = styled.p`
  & i {
    color: var(--primary-color);

    @media (max-width: 992px) {
      display: inline-block;
      font-weight: 700;
    }
  }
`;

const ScrollCrest = styled.img`
  float: right;
  height: 300px;
  margin: 20px 20px 0;
`;

const FoundersImage = styled.img`
  width: 80%;
  margin: 50px 75px 180px;
  filter: grayscale(80%);

  @media (max-width: 992px) {
    width: 100%;
    margin: 50px 0 0;
    filter: grayscale(80%);
  }
`;
