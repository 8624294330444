import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { animateScroll as scroll, scroller } from 'react-scroll';
import { isChrome } from '../../../shared/helpers.js';
import './NavBar.css';

function scrollToTop() {
  scroll.scrollToTop({
    duration: 1000
  });
}

/* Scrolls to the selected section. */
function scrollToComp(name, offset = 0) {
  scroller.scrollTo(name, {
    duration: 1000,
    offset: offset,
    smooth: true
  });
}

function NavBar() {
  return (
    <>
      <Navbar collapseOnSelect sticky="top" expand="sm" variant="dark">
        <Navbar.Brand onClick={scrollToTop}>
          <img
            className="logo nav-logo"
            src={isChrome ? require('../../../shared/logo.webp') : require('../../../shared/logo.png')}
            alt="CyrusInterpreting.com"
          />
        </Navbar.Brand>
        <Navbar.Brand className="nav-title" onClick={scrollToTop}>
          CyrusInterpreting.com
        </Navbar.Brand>
        <Navbar.Toggle className="ml-auto" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="contact">Contact</Nav.Link>
            <Nav.Link onClick={() => scrollToComp('process')}>Services</Nav.Link>
            <Nav.Link onClick={() => scrollToComp('about-us')}>About</Nav.Link>
            <Nav.Link onClick={() => scrollToComp('faq')}>Languages</Nav.Link>
            <Nav.Link href="quote" className="nav-quote">Free Quote</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export { NavBar };
