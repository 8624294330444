const stepData = [
  {
    icon: 'FaComments',
    position: 'odd',
    title: 'Submit',
    description:
      'Submit document for a quote.'
  },
  {
    icon: 'FaUsers',
    position: 'even',
    title: 'Review',
    description:
      'Translator to estimate amount of work required for translation.'
  },
  {
    icon: 'FaEnvelope',
    position: 'odd',
    title: 'Bid',
    description:
      'CyrusInterpreting to return cost of translation.'
  },
  {
    icon: 'FaCheckCircle',
    position: 'even',
    title: 'Translate',
    description:
      'Translation to begin per client approval.'
  }
];

export default stepData;
