import React, { Component } from 'react';
import styled from 'styled-components';
import { isChrome } from '../../shared/helpers.js';
import { images } from '../../activeData/data.js';
import './Contact.css';
import { ContactForm } from './ContactForm.js';

export default class Contact extends Component {
  state = {
    image: null,
    imageIndex: 0,
    alumni: [],
    specificLabel: '',
    showModal: false,
    selectedBrother: null,
    showList: false
  };

  /* Runs when component mounts */
  componentDidMount() {
    const image = document.getElementsByClassName('brothers-image');

    this.setState({
      image
    });
  }

  /* Closes the modal */
  close = () => this.setState({ showModal: false });

  /* Opens the modal */
  open = brother => {
    this.setState({
      showModal: true,
      selectedBrother: brother
    });
  };

  render() {
    const {
      imageIndex,
      specificLabel
    } = this.state;
    const logoWebp = require('../../shared/logo.webp');
    const logoPng = require('../../shared/logo.png');
    return (
      <div>
        <BrothersHeader>
          <LogoAnchor role="button" href="/">
            <img className="logo" src={isChrome ? logoWebp : logoPng} alt="Logo" />
          </LogoAnchor>
          {specificLabel || 'Contact Us - Email or Call (619) 321-8185'}
        </BrothersHeader>
        <HeroImageContainer>
          {images.map((image, i) => (
            <HeroImage src={isChrome ? image.webp : image.jpg} alt="Contact Us" selected={imageIndex === i} key={i} />
          ))}
        </HeroImageContainer>
        <ScrollDown />
        <ScrollingGrid>
          <ContactForm />
        </ScrollingGrid>
      </div>
    );
  }
}

const BrothersHeader = styled.div`
  position: fixed;
  width: 100%;
  background: rgba(46, 47, 51, 1);
  color: #fff;
  font-size: 45px;
  padding: 10px 0;
  text-align: initial;
  font-family: 'Helvetica Neue Condensed' !important;
  z-index: 3;

  @media (max-width: 768px) {
    font-size: 25px;
    padding: 10px 0;
  }
`;

const LogoAnchor = styled.a`
  margin: 0 25px;
`;

const HeroImageContainer = styled.div`
  position: relative;
  top: 60px;
  background-color: #000;
  height: 30vh;
  min-height: 30vh;
  z-index: -1;
`;

const HeroImage = styled.img`
  display: block;
  position: fixed;
  top: 87px; /* height of the header */
  height: calc(100% - 87px);
  width: 100%;
  object-fit: cover;
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  z-index: -1;

  ${props =>
    props.selected &&
    `
    opacity: 1;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    filter: alpha(opacity=1);
  `}

  @media (max-width: 768px) {
    top: 60px; /* height of the header */
    height: calc(100% - 60px);
  }
`;

const ScrollDown = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 40px;
  display: block;
  text-decoration: none;
  width: 30px;
  height: 30px;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  z-index: 1;
  transform: translate(-50%, 0%) rotate(45deg);
  animation: fadeMoveDown 4s ease-in-out infinite;

  /*animated scroll arrow animation*/
  @-webkit-keyframes fadeMoveDown {
    0% {
      -webkit-transform: translate(0, -10px) rotate(45deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 10px) rotate(45deg);
      opacity: 0;
    }
  }
  @-moz-keyframes fadeMoveDown {
    0% {
      -moz-transform: translate(0, -10px) rotate(45deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -moz-transform: translate(0, 10px) rotate(45deg);
      opacity: 0;
    }
  }
  @keyframes fadeMoveDown {
    0% {
      transform: translate(0, -10px) rotate(45deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translate(0, 10px) rotate(45deg);
      opacity: 0;
    }
  }
`;

const ScrollingGrid = styled.div`
  background-color: #fff;
  position: relative;
  height: 100%;
  min-height: 100%;
  width: 80%;
  margin: auto;
  z-index: 1;
`;
