import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import DropzoneComponent from 'react-dropzone-component';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';

import { StatusModal } from '../../shared/StatusModal.js';

import './ContactForm.css';
import './dropzone.css';

const defaultState = {
  name: '',
  email: '',
  phone: '',
  company: '',
  industry: '',
  langFrom: '',
  langTo: '',
  numWords: '',
  message: '',
  modalMessage: '',
  modalTitle: '',
  isWarningModal: false,
  redirect: false,
  showModal: false
};

class QuoteForm extends React.Component {
  constructor(props) {
    super(props);

    this.componentConfig = {
      showFiletypeIcon: true,
      // postUrl: 'no-url'
      postUrl: "/api/v1/quote"
    };

    this.state = Object.assign({}, defaultState);
    this.dropzone = null;
  }

  componentDidMount() {
    this.state = Object.assign({}, defaultState);
  }

  get statusModal() {
    return <StatusModal
             show={this.state.showModal}
             close={this.close}
             title={this.state.modalTitle}
             message={this.state.modalMessage}
           />;
  }

  /* Closes the modal */
  close = () => this.setState({
    modalMessage: '',
    modalTitle: '',
    redirect: this.state.isWarningModal ? false : true,
    showModal: false
  });

  /* Opens the modal */
  open = (title, message, isWarningModal) => {
    this.setState({
      isWarningModal: isWarningModal,
      modalMessage: message,
      modalTitle: title,
      showModal: true
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.dropzone.processQueue();
    this.resetForm();
  };

  resetForm() {
    if (this.dropzone && this.file) {
      // this.file.removeFile();
      this.dropzone.removeFile();
    }
    this.setState(Object.assign({}, defaultState));
  }

  onNameChange(event) {
    this.setState({name: event.target.value});
  }

  onEmailChange(event) {
    this.setState({email: event.target.value});
  }

  onPhoneChange(event) {
    this.setState({phone: event.target.value});
  }

  onMessageChange(event) {
    this.setState({message: event.target.value});
  }

  handleFileAdded(file) {
    if (file.length === 0) { return; }
    this.setState({file: file});
  }

  handleToken(token) {
    this.setState({token: token});
  }

  render() {
    const componentConfig = this.componentConfig;

    const eventHandlers = {
      init: dz => this.dropzone = dz,
      addedfile: this.handleFileAdded.bind(this),
      queuecomplete: files => this.open("Message Sent", "Thank you for contacting us. You are very important to us, all information received will always remain confidential.  We will contact you as soon as we review your message.", false)
    };

    const { redirect } = this.state;
    if (redirect) { return (<Redirect to='/'/>); }

    return (
      <Container>
        <GoogleReCaptchaProvider reCaptchaKey="6LfLTuEUAAAAAGCy9TWisSIr2YZn5RU7AJz2VONb">
          <GoogleReCaptcha onVerify={token => this.setState({token: token})} />
          <form id="contact-form" method="POST" onSubmit={this.handleSubmit.bind(this)}>
        <FlexWrapper>
        <InputContainer>
        <InputField
          type="text"
          placeholder="Name (Required)"
          name="name"
          value={this.state.name}
          onChange={this.onNameChange.bind(this)}
          required
        />
        </InputContainer>
        <InputContainer>
        <InputField
          type="email"
          placeholder="E-mail (Required)"
          name="email"
          value={this.state.email}
          onChange={this.onEmailChange.bind(this)}
          required
        />
        </InputContainer>
        <InputContainer>
        <InputField
          type="text"
          placeholder="Phone (Optional)"
          name="phone"
          value={this.state.phone}
          onChange={this.onPhoneChange.bind(this)}
        />
        </InputContainer>
        <InputContainer>
        <InputField
          type="text"
          placeholder="Company Name (Optional)"
          name="company"
        />
        </InputContainer>
        <InputContainer>
        <InputField
          type="text"
          placeholder="Industry (Optional)"
          name="industry"
        />
        </InputContainer>
        <InputContainer>
        <InputField
          type="text"
          placeholder="Language Translated From"
          name="langFrom"
        />
        </InputContainer>
        <InputContainer>
        <InputField
          type="text"
          placeholder="Language Translated To"
          name="langTo"
        />
        </InputContainer>
        <InputContainer>
        <InputField
          type="text"
          placeholder="Number of Words"
          name="numWords"
        />
        </InputContainer>
        <InputContainer>
        </InputContainer>
        </FlexWrapper>
    <div className="form-field">
        <TextAreaContainer>
          <MessageInput
            rows={5}
            type="text"
            placeholder="Message (Optional)"
            name="message"
            value={this.state.message}
            onChange={this.onMessageChange.bind(this)}
          />
        </TextAreaContainer>
    </div>
    <div className="form-field">
        <DropzoneContainer>
          <DropzoneComponent
            config={componentConfig}
            djsConfig={{
              addRemoveLinks: true,
              autoProcessQueue: false,
              dictDefaultMessage: "Drop files here or click to upload",
              maxFiles: 10,
              maxfilesexceeded: files => this.open("Max File Limit Exceeded", "Please reduce the number of files to upload or request multiple free quotes.", true),
              maxfilesreached: files => this.open("Max File Limit Reached", "A maximum of 10 documents may be uploaded for quote.  If you have more than 10, please submit multiple quote requests.", true),
              parallelUploads: 10,
              params: {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                company: this.state.company,
                industry: this.state.industry,
                langFrom: this.state.langFrom,
                langTo: this.state.langTo,
                numWords: this.state.numWords,
                message: this.state.message,
                token: this.state.token
              }
            }}
            eventHandlers={eventHandlers}
          />
        </DropzoneContainer>
    </div>
    <QuoteButton type="submit">Free Quote</QuoteButton>
    {this.statusModal}
  </form>
          </GoogleReCaptchaProvider>
  </Container>
  );
  }
}

export { QuoteForm };

const Container = styled.div`
  display: flex;
  justify-content: center;
  top: 85.3px;
  background-color: #fff;
  padding: 20px 0;
  margin: auto;
  border-bottom: 1px solid #ddd;
  z-index: 10;

  @media (min-width: 768px) {
    position: sticky;
    top: 85.3px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
`;

const InputField = styled.input`
  width: 70%;
  height: 42px;
  padding: 0.375rem 0.75rem;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid hsl(0, 0%, 70%);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const InputContainer = styled.div`
  width: 33%;

  @media (max-width: 768px) {
    max-width: 500px;
    width: 100%;
    margin: 10px 0;
  }
`;

const DropzoneContainer = styled.div`
  width: 90%;
  margin: auto;

  @media (max-width: 768px) {
    max-width: 500px;
    width: 90%;
    margin: 10px 0;
  }
`;

const MessageInput = styled.textarea`
  width: 90%;
  height: 168px;
  padding: 0.375rem 0.75rem;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid hsl(0, 0%, 70%);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const QuoteButton = styled.button`
  top: 320px;
  left: 50%;
  background-color: #777;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 20px;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.63) 0px 3px 10px;
  cursor: pointer;
  white-space: nowrap;
  z-index: 1;

  &:hover {
    background-color: #666;
    color: #fff;
  }
`;

const TextAreaContainer = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    max-width: 500px;
    width: 100%;
    margin: 10px 0;
  }
`;
