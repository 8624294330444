import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { StatusModal } from '../../shared/StatusModal.js';
import './ContactForm.css';

const defaultState = {
  name: '',
  email: '',
  phone: '',
  message: '',
  modalMessage: '',
  modalTitle: '',
  redirect: false,
  showModal: false
};

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, defaultState);
  }

  componentDidMount() {
    this.state = Object.assign({}, defaultState);
  }

  get statusModal() {
    return <StatusModal
             show={this.state.showModal}
             close={this.close}
             title={this.state.modalTitle}
             message={this.state.modalMessage}
           />;
  }

  /* Closes the modal */
  close = () => this.setState({
    modalMessage: '',
    modalTitle: '',
    redirect: true,
    showModal: false
  });

  /* Opens the modal */
  open = (title, message) => {
    this.setState({
      modalMessage: message,
      modalTitle: title,
      showModal: true
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    axios({
      method: "POST",
      url:"/api/v1/contact",
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success'){
        this.open("Message Sent", "Thank you for contacting us. You are very important to us, all information received will always remain confidential.  We will contact you as soon as we review your message.");
        // this.resetForm()
      }else if(response.data.status === 'fail'){
        this.open("Message Failure", "Message failed to send.  Please correct any errors and/or try again.");
      }
    })
  }

  resetForm() {
    this.setState(Object.assign({}, defaultState));
  }

  onNameChange(event) {
    this.setState({name: event.target.value})
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onPhoneChange(event) {
    this.setState({phone: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }

  render() {
    const { redirect } = this.state;
    if (redirect) { return (<Redirect to='/'/>); }

    return (
      <Container>
        <GoogleReCaptchaProvider reCaptchaKey="6LfLTuEUAAAAAGCy9TWisSIr2YZn5RU7AJz2VONb">
          <GoogleReCaptcha onVerify={token => this.setState({token: token})} />
        <form id="contact-form" method="POST" onSubmit={this.handleSubmit.bind(this)}>
        <FlexWrapper>
        <InputContainer>
        <InputField
          type="text"
          placeholder="Name (Required)"
          name="name"
          value={this.state.name}
          onChange={this.onNameChange.bind(this)}
          required
        />
        </InputContainer>
        <InputContainer>
        <InputField
          type="email"
          placeholder="E-mail (Required)"
          name="email"
          value={this.state.email}
          onChange={this.onEmailChange.bind(this)}
          required
        />
        </InputContainer>
        <InputContainer>
        <InputField
          type="text"
          placeholder="Phone (Optional)"
          value={this.state.phone}
          onChange={this.onPhoneChange.bind(this)}
          name="phone"
        />
        </InputContainer>
        </FlexWrapper>
    <br />
    <div className="form-field">
        <TextAreaContainer>
          <MessageInput
            rows={5}
            type="text"
            placeholder="Message (Required)"
            name="message"
            value={this.state.message}
            onChange={this.onMessageChange.bind(this)}
            required
          />
        </TextAreaContainer>
    </div>
    <SendButton type="submit">Send Message</SendButton>
    {this.statusModal}
    </form>
    </GoogleReCaptchaProvider>
  </Container>
  );
  }
}

export { ContactForm };

const Container = styled.div`
  display: flex;
  justify-content: center;
  top: 85.3px;
  background-color: #fff;
  padding: 20px 0;
  margin: auto;
  border-bottom: 1px solid #ddd;
  z-index: 10;

  @media (min-width: 768px) {
    position: sticky;
    top: 85.3px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 960px;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
`;

const SendButton = styled.button`
  top: 320px;
  left: 50%;
  background-color: #777;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 20px;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.63) 0px 3px 10px;
  cursor: pointer;
  white-space: nowrap;
  z-index: 1;

  &:hover {
    background-color: #666;
    color: #fff;
  }
`;

const InputField = styled.input`
  width: 70%;
  height: 42px;
  padding: 0.375rem 0.75rem;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid hsl(0, 0%, 70%);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const InputContainer = styled.div`
  width: 33%;

  @media (max-width: 768px) {
    max-width: 500px;
    width: 100%;
    margin: 10px 0;
  }
`;

const MessageInput = styled.textarea`
  width: 90%;
  height: 168px;
  padding: 0.375rem 0.75rem;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid hsl(0, 0%, 70%);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const TextAreaContainer = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    max-width: 500px;
    width: 100%;
    margin: 10px 0;
  }
`;
