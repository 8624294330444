const langColRows = [
  {
    title: 'Arabic',
    body: 'Algeria, Bahrain, Egypt, Iraq, Jordan, Kuwait, Lebanon, Libya, Morocco, Oman, Qatar, Saudi Arabia, Sudan, Syria, United Arab Emirates, Yemen'
  },
  {
    title: 'Dari',
    body: 'Dari (or Eastern Persian); Afghanistan'
  },
  {
    title: 'Farsi',
    body: 'Farsi (or Persian); Iran, Tajikistan, Uzbekistan, Iraq, Russia, Azerbaijan and Afghanistan.'
  }
];

export { langColRows };