import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import App from './components/App';
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import Quote from './components/Quote/Quote';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <BrowserRouter>
    <App>
      <Switch>
        <Route exact path="/quote" component={Quote} />
        <Route exact path="/contact" component={Contact} />
        <Route path="*" component={Home} />
      </Switch>
    </App>
  </BrowserRouter>,
  document.getElementById('root')
);
