const slideData = [
  {
    name: 'medical',
    image: require('./images/cyrus-the-great.webp'),
    safari: require('./images/cyrus-the-great.jpg')
  },
  {
    name: 'legal',
    image: require('./images/map-me.webp'),
    safari: require('./images/map-me.jpg')
  },
  {
    name: 'industry',
    image: require('./images/persian-tile.webp'),
    safari: require('./images/persian-tile.jpg')
  }
];

export { slideData };
