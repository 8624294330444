const brothers = [
];

/* Deprecated */
const alumni = [
  {
  }
];

const options = {
  general: [
    { value: 'active', label: 'Active' }
  ],
  active: [],
  major: [
    {
    }
  ],
  class: [
  ]
};

const images = [
  {
    name: 'active',
    webp: require('./images/general/inscription.webp'),
    jpg: require('./images/general/inscription.jpg')
  }
];

export { brothers, alumni, options, images };
