const gearPosition = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];

const pillarData = [
  {
    info: 'Secure',
    description: 'All translations are conducted in a safe and secure manner',
    name: 'Confidentiality',
    content: 'All translations are conducted in a safe and secure manner.',
    image: require('./images/security.webp'),
    safari: require('./images/security.jpg')
  },
  {
    info: 'Integrity',
    description: 'Integrity, ethics, honesty, and respect are principals all our translators follow',
    name: 'Professionalism',
    content: 'Integrity, ethics, honesty, and respect are principals all our translators follow.',
    image: require('./images/crossroads.webp'),
    safari: require('./images/crossroads.jpg')
  },
  {
    info: 'Quality',
    description: 'We stand by our work and deliver the highest in quality translations',
    name: 'Quality',
    content: 'We deliver the highest quality translation services.',
    image: require('./images/quality.webp'),
    safari: require('./images/quality.jpg')
  }
];

export { gearPosition, pillarData };
