import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

function StatusModal({ show, close, title, message }) {
  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
    </Modal>
  );
}

export { StatusModal };

const StatusInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Info = styled.div`
  color: #666;
  font-size: 20px;
  margin: 0 0 1em;

  @media (max-width: 539px) {
    font-size: 16px;
  }
`;

const Title = styled.h2`
  font-size: 30px;
  color: var(--secondary-color);

  @media (max-width: 539px) {
    font-size: 20px;
    margin: 0 0 5px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 0;
  border-top: 1px solid #dee2e6;
  cursor: pointer;

  @media (max-width: 320px) {
    font-size: 18px;
  }
`;

const FooterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled(FooterButton)`
  width: 50%;
  height: 60px;
  color: #9a0000;
  border-right: 1px solid #dee2e6;

  &:hover {
    background-color: #eee;
  }
`;

