import './Process.css';
import { isChrome } from '../../../shared/helpers.js';

import React from 'react';
import { Element } from 'react-scroll';
import { Container, Col, Row } from 'react-bootstrap';
import { FaComments, FaUsers, FaEnvelope, FaCheckCircle } from 'react-icons/fa';
import styled from 'styled-components';
import stepData from './data.js';

function Process() {
  return (
    <Element name="process" className="element process">
      <img
        src={ isChrome ? require('./images/covid-response.webp') : require('./images/covid-response.png') }
        alt="COVID-19 Response"
      />
      <Container>
        <p className="process-extra-text">Language barriers and mixed responses leave some US communities without vital Coronovirus information.  We are here to translate any Coronavirus and COVID-19 related materials for your Farsi, Dari, Arabic, readers or communities.  We have medical translators with accurate and fast turn-around in order to reach your LEPs as soon as possible.</p>
      </Container>
      <h1 className="title">Translation Services</h1>
      <Container>
        <p className="process-extra-text">Reliability and punctuality to deliver the job on time is our top priority.  Our staff consists of highly educated and quality linguists to help you with your needs.  ATA and ALTA certified linguists at your service.</p>
      </Container>
      <h2 className="process-process">Document Translation Process</h2>
      <Container>
        <Row className="table-row">
          {stepData.map((step, i) => (
            <Col className={`${step.position}`} sm={12} lg={3} key={i}>
              <div className={`process-steps ${step.position}`}>
                { step.icon === 'FaComments' && <FaComments size="2em" className="process-step-icon" /> }
                { step.icon === 'FaUsers' && <FaUsers size="2em" className="process-step-icon" /> }
                { step.icon === 'FaEnvelope' && <FaEnvelope size="2em" className="process-step-icon" /> }
                { step.icon === 'FaCheckCircle' && <FaCheckCircle size="2em" className="process-step-icon" /> }
                <span className="step-title">{ step.title }</span>
                <span className="step-description">{ step.description }</span>
              </div>
              <div className={`step-triangle ${step.position}`} />
            </Col>
          ))}
        </Row>
      </Container>

      <Container>
        <h2 className="process-process">Certified Translation</h2>
        <p className="process-extra-text">A word-for-word human translation of documents such as Birth Certificate, Passport, National ID, Divorce and Death Certificate, Medical Transcripts, Educational Certificates and Diploma, Legal and Social documents, as well as private notes, books, and much more.  Delivered to you both electronically or by snail mail.  All our documents carry the letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments.</p>
        <h4>OPTIONS</h4>
        <Row>
          <Col>
            <Card>
              <h5>Notarization</h5><p className="process-extra-text">Stamp and signature authenticating the signer of the translation certification. Valid in all 50 states.</p>
            </Card>
          </Col>
          <Col>
            <Card>
              <h5>Expedited</h5><p className="process-extra-text">Your order will be prioritized and turnaround time for digital delivery by 50% of the normal delivery.  Birth Certificates are within 24 hrs.</p>
            </Card>
          </Col>
          <Col>
            <Card>
              <h5>Hard Copy</h5><p className="process-extra-text">Trackable shipping by USPS Priority Mail or Express Mail in addition to our standard digital delivery.</p>
            </Card>
          </Col>
        </Row>
      </Container>
    </Element>
  );
}

export { Process };

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
  margin: 15px auto;
  border: none;
  border-radius: 0.25rem;
  text-align: left;

  @media(max-width: 992px) {
    max-width: 370px;
  }
`;

